// noinspection JSUnusedGlobalSymbols

import { EntityType } from './helpers';

export type EntityTypeLockSourceEntity = {
  entityID: string;
  entityName: string;
};

/* These entities are only used for providing content on otherwise-locked pages
 * Those locked pages would have no content if we don't do this */
export const LockSourceEntities: Record<EntityType, EntityTypeLockSourceEntity> = {
  [EntityType.EMPLOYER]: {
    entityID: 'amazon-services~1',
    entityName: 'Amazon',
  },
  [EntityType.BROKER]: {
    entityID: 'm3-insurance-solutions~1',
    entityName: 'M3 Insurance Solutions',
  },
  [EntityType.AGENT]: {
    entityID: '',
    entityName: '',
  },
  [EntityType.CARRIER]: {
    entityID: 'companion-life-insurance~1',
    entityName: 'Companion Life',
  },
};

export enum StaticRefEnum {
  USER_GUIDE = 'static/Benefeature User Guide.pdf',
  EXTRACT_DATA_DICTIONARY = 'reports/Benefeature Extract Data Dictionary.pdf',
  THREE_YEAR_LOOKBACK_EXTRACT = 'reports/benefeature-3-year-lookback.csv',
  FIVE_YEAR_LOOKBACK_EXTRACT = 'reports/benefeature-5-year-lookback.csv',
  COMPLETE_EXTRACT = 'reports/benefeature-complete.csv',
}

export const MarketingUrlMap = {
  HOME: `${process.env.NEXT_PUBLIC_MARKETING_DOMAIN}/`,

  PRICING: `${process.env.NEXT_PUBLIC_MARKETING_DOMAIN}/pricing`,
  SUPPORT: `${process.env.NEXT_PUBLIC_MARKETING_DOMAIN}/support`,

  PRIVACY_POLICY: `${process.env.NEXT_PUBLIC_MARKETING_DOMAIN}/policies/privacy`,
  TERMS_OF_SERVICE: `${process.env.NEXT_PUBLIC_MARKETING_DOMAIN}/policies/terms-of-service`,
};

export enum RouteEnum {
  HOME = '/',
  SUPPORT = '/support',

  /* Auth */
  AUTH_LOGIN = '/auth/login',
  AUTH_SIGNUP = '/auth/signup',
  AUTH_ACCOUNT_SETUP = '/auth/account-setup',
  AUTH_VERIFY_REQUEST = '/auth/verify-request',
  AUTH_COMPLETE_VERIFY_REQUEST = '/auth/complete-verify-request',
  AUTH_ERROR = '/auth/error',

  /* Exports */
  EXPORTS = '/exports',
  REPORT_EXPORTS = '/exports/reports',
  DATA_EXTRACT_EXPORTS = '/exports/data-extracts',

  /* Knowledge base */
  KNOWLEDGE_BASE = '/knowledge-base',
  KNOWLEDGE_BASE_ARTICLE = '/knowledge-base/[id]',

  /* Key statistics */
  KEY_STATISTICS = '/key-statistics',
  KEY_STATISTICS_STATE = '/key-statistics/state/[state]',
  KEY_STATISTICS_INDUSTRY = '/key-statistics/industry/[industry]',

  /* Base search page redirects to entity-specific variants
     Do not remove specific search entity enums, all are used via reflection */
  SEARCH = '/search',
  SEARCH_SEARCH_TYPE = '/search/[search_type]',
  SEARCH_EMPLOYERS = '/search/employers',
  SEARCH_BROKERS = '/search/brokers',
  SEARCH_AGENTS = '/search/agents',
  SEARCH_CARRIERS = '/search/carriers',

  /* Favorites */
  FAVORITES = '/favorites',

  /* Employers */
  EMPLOYER_OVERVIEW = '/employers/[company_id]',
  EMPLOYER_WELFARE = '/employers/[company_id]/welfare',
  EMPLOYER_INSURANCE = '/employers/[company_id]/insurance',
  EMPLOYER_RETIREMENT = '/employers/[company_id]/retirement',
  EMPLOYER_PROVIDERS = '/employers/[company_id]/providers',
  EMPLOYER_CONTACTS = '/employers/[company_id]/contacts',
  EMPLOYER_PRINT = '/employers/[company_id]/print',

  /* Brokers */
  BROKER_OVERVIEW = '/brokers/[broker_id]',
  BROKER_BOOK_OF_BUSINESS = '/brokers/[broker_id]/book-of-business',
  BROKER_OFFICES_AND_AGENTS = '/brokers/[broker_id]/offices-and-agents',
  BROKER_CARRIERS = '/brokers/[broker_id]/carriers',
  BROKER_COMPETITION = '/brokers/[broker_id]/competition',
  BROKER_PRINT = '/brokers/[broker_id]/print',

  /* Agents */
  AGENT_OVERVIEW = '/agents/[npn]',
  AGENT_BOOK_OF_BUSINESS = '/agents/[npn]/book-of-business',
  AGENT_PRINT = '/agents/[npn]/print',

  /* Carriers */
  CARRIER_OVERVIEW = '/carriers/[carrier_id]',
  CARRIER_BOOK_OF_BUSINESS = '/carriers/[carrier_id]/book-of-business',
  CARRIER_BROKERS = '/carriers/[carrier_id]/brokers',
  CARRIER_COMPETITION = '/carriers/[carrier_id]/competition',
  CARRIER_PRINT = '/carriers/[carrier_id]/print',

  /* Admin */
  ADMIN = '/admin',
  ADMIN_CUSTOM_FILTERS = '/admin/custom-filters',
  ADMIN_NEW_CUSTOM_FILTER = '/admin/custom-filters/new',
  ADMIN_CUSTOM_FILTER_DETAILS = '/admin/custom-filters/[custom_filter_id]',
  ADMIN_PERMISSIONS = '/admin/permissions',
  ADMIN_NEW_PERMISSION = '/admin/permissions/new',
  ADMIN_PERMISSION_DETAILS = '/admin/permissions/[permission_id]',
  ADMIN_USERS = '/admin/users',
  ADMIN_NEW_USER = '/admin/users/new',
  ADMIN_USER_DETAILS = '/admin/users/[uid]',
  ADMIN_TEAMS = '/admin/teams',
  ADMIN_NEW_TEAM = '/admin/teams/new',
  ADMIN_TEAM_DETAILS = '/admin/teams/[team_id]',

  /* Team Management */
  MANAGE_TEAM_DETAILS = '/teams/[team_id]',
  MANAGE_USER_DETAILS = '/users/[uid]',

  /* Dashboards */
  DASHBOARDS = '/dashboard',
  DASHBOARD_TYPE_DYNAMIC = '/dashboard/[dashboard_type]',
  DASHBOARD_TERRITORY_MANAGEMENT_OVERVIEW = '/dashboard/territory-management',
  DASHBOARD_TERRITORY_MANAGEMENT_PROSPECTING = '/dashboard/territory-management/prospecting',

  /* Custom pages */
  DASHBOARD_MOO_CUSTOM_TERRITORY_MANAGEMENT = '/dashboard/moo-territory-management',
}

export enum DASHBOARD_TYPES {
  CARRIER_TERRITORY_MANAGEMENT = 'territory-management',
  MOO_TERRITORY_MANAGEMENT = 'moo-territory-management',
}

export const RoutesWithDefaultRedirects: string[] = [
  /* Basic and auth */
  RouteEnum.HOME,
  RouteEnum.AUTH_LOGIN,
  RouteEnum.AUTH_SIGNUP,
  RouteEnum.AUTH_ERROR,
  RouteEnum.AUTH_VERIFY_REQUEST,
  RouteEnum.AUTH_COMPLETE_VERIFY_REQUEST,
  RouteEnum.AUTH_ACCOUNT_SETUP,

  /* Signup flow */
  RouteEnum.AUTH_SIGNUP,
];

export enum ApiEnum {
  // Root level
  REGISTER_USER_ACTIVITY = '/api/user-activity',

  // Admin
  ADMIN_CURRENT_SESSIONS = '/api/admin/current-sessions',
  ADMIN_ACTIVITY_FEED = '/api/admin/activity-feed',
  ADMIN_CUSTOM_FILTERS = '/api/admin/custom-filters',
  ADMIN_CUSTOM_FILTER = '/api/admin/custom-filters/[custom_filter_id]',
  ADMIN_PERMISSIONS = '/api/admin/permissions',
  ADMIN_PERMISSION = '/api/admin/permissions/[permission_id]',

  // Users
  USERS_LIST = '/api/users',
  USERS_CHECK_EMAIL = '/api/users/check-email',
  USER_DETAILS = '/api/users/[uid]',
  USER_ACTIVITY_FEED = '/api/users/[uid]/activity-feed',
  USER_ACCOUNTS = '/api/users/[uid]/accounts',
  USER_ACCOUNT_DETAILS = '/api/users/[uid]/accounts/[account_id]',
  USER_CONTEXT = '/api/users/[uid]/context',
  USER_SHARES = '/api/users/[uid]/shares',
  USER_NOTIFICATIONS = '/api/users/[uid]/notifications',
  USER_EXPORTS = '/api/users/[uid]/exports',
  USER_EXPORT_DOWNLOAD_LINK = '/api/users/[uid]/exports/[export_id]/download-link',
  USER_FAVORITES = '/api/users/[uid]/favorites',
  USER_RESEND_WELCOME = '/api/users/[uid]/resend-welcome',
  USER_PERMISSIONS = '/api/users/[uid]/permissions',

  // Teams
  TEAMS_LIST = '/api/teams',
  TEAMS_REQUEST_ENTERPRISE_INFO = '/api/teams/request-enterprise-info',
  TEAM_DETAIL = '/api/teams/[team_id]',
  TEAM_MANAGERS = '/api/teams/[team_id]/managers',
  TEAM_MEMBERS = '/api/teams/[team_id]/members',
  TEAM_CONTEXT = '/api/teams/[team_id]/context',
  TEAM_SUBSCRIPTIONS = '/api/teams/[team_id]/subscriptions',
  TEAM_CUSTOM_FILTERS = '/api/teams/[team_id]/custom-filters',
  TEAM_PERMISSIONS = '/api/teams/[team_id]/permissions',
  TEAM_MEMBER_PERMISSIONS = '/api/teams/[team_id]/member-permissions',

  // Exports
  EXPORT_PDF = '/api/export/pdf',
  EXPORT_EXCEL = '/api/export/excel',

  // Static Refs
  STATIC_REFS_DOWNLOAD_LINK = '/api/static-refs/download-link',

  // Metadata
  VERSION = '/api/version',
  QUERY_CACHE = '/api/query-cache',
  IMAGE_CACHE_VERSION = '/api/image-cache-version',

  // Statistics
  STATE_STATS = '/api/key-statistics/state',
  INDUSTRY_STATS = '/api/key-statistics/industry',

  // Brokers
  BROKERS = '/api/brokers',
  BROKER_SUMMARY = '/api/brokers/[broker_id]',
  BROKER_PROFILE_FILTERS = '/api/brokers/[broker_id]/profile-filters',
  BROKER_BOOK_OF_BUSINESS = '/api/brokers/[broker_id]/book-of-business',
  BROKER_CORE_DETAILS = '/api/brokers/[broker_id]/core-details',
  BROKER_WELFARE_BENEFIT_PRODUCTS = '/api/brokers/[broker_id]/welfare-benefit-products',
  BROKER_MARKET_PRESENCE = '/api/brokers/[broker_id]/market-presence',

  // Agents
  AGENTS = '/api/agents',
  AGENT_SUMMARY = '/api/agents/[npn]',
  AGENT_APPOINTMENTS = '/api/agents/[npn]/appointments',
  AGENT_LICENSES = '/api/agents/[npn]/licenses',
  AGENT_PROFILE_FILTERS = '/api/agents/[npn]/profile-filters',

  // Carriers
  CARRIERS = '/api/carriers',
  CARRIER_SUMMARY = '/api/carriers/[carrier_id]',
  CARRIER_PROFILE_FILTERS = '/api/carriers/[carrier_id]/profile-filters',
  CARRIER_BOOK_OF_BUSINESS = '/api/carriers/[carrier_id]/book-of-business',
  CARRIER_CORE_DETAILS = '/api/carriers/[carrier_id]/core-details',
  CARRIER_WELFARE_BENEFIT_PRODUCTS = '/api/carriers/[carrier_id]/welfare-benefit-products',
  CARRIER_MARKET_PRESENCE = '/api/carriers/[carrier_id]/market-presence',
  CARRIER_DIRECT_VS_BROKERED_PLANS = '/api/carriers/[carrier_id]/direct-vs-brokered-plans',

  // Employers
  EMPLOYERS = '/api/employers',
  EMPLOYER_SUMMARY = '/api/employers/[company_id]',
  EMPLOYER_AVAILABLE_EINS = '/api/employers/[company_id]/available-eins',
  EMPLOYER_NAME = '/api/employers/[company_id]/name',
  EMPLOYER_CONTACTS = '/api/employers/[company_id]/contacts',
  EMPLOYER_PROVIDERS = '/api/employers/[company_id]/providers',
  EMPLOYER_RELATED_EMPLOYERS = '/api/employers/[company_id]/related-employers',
  EMPLOYER_USE_YEARS = '/api/employers/[company_id]/use-years',

  // Employer welfare benefits
  EMPLOYER_INSURANCE_BENEFITS_AVAILABLE = '/api/employers/[company_id]/welfare-benefits/available',
  EMPLOYER_INSURANCE_BENEFITS_CARRIERS_AVAILABLE = '/api/employers/[company_id]/welfare-benefits/available-carriers',
  EMPLOYER_INSURANCE_BENEFITS_BROKERS_AVAILABLE = '/api/employers/[company_id]/welfare-benefits/available-brokers',
  EMPLOYER_INSURANCE_BENEFITS_BROKER_RELATIONSHIPS = '/api/employers/[company_id]/welfare-benefits/broker-relationships',
  EMPLOYER_INSURANCE_BENEFITS_CARRIER_RELATIONSHIPS = '/api/employers/[company_id]/welfare-benefits/carrier-relationships',
  EMPLOYER_INSURANCE_BENEFITS_GROUPS = '/api/employers/[company_id]/welfare-benefits/groups',
  EMPLOYER_INSURANCE_BENEFITS_PRODUCT_PARTICIPATION = '/api/employers/[company_id]/welfare-benefits/product-participation',
  EMPLOYER_INSURANCE_BENEFITS_PRODUCT_TRENDS = '/api/employers/[company_id]/welfare-benefits/product-trends',
  EMPLOYER_INSURANCE_BENEFITS_RELATIONSHIP_OVERVIEW = '/api/employers/[company_id]/welfare-benefits/relationship-overview',
  EMPLOYER_INSURANCE_BENEFITS_SUMMARY = '/api/employers/[company_id]/welfare-benefits/summary',

  // Employer financial assets
  EMPLOYER_FINANCIAL_ASSETS_SUMMARY = '/api/employers/[company_id]/financial-assets/summary',

  // Search
  SEARCH_EMPLOYERS = '/api/employers/search',
  SEARCH_BROKERS = '/api/brokers/search',
  SEARCH_CARRIERS = '/api/carriers/search',

  // Stripe
  STRIPE_CREATE_CHECKOUT_SESSION = '/api/stripe/create-checkout-session',
  STRIPE_CREATE_PORTAL_LINK = '/api/stripe/create-portal-link',

  // Projections
  PROJECTIONS_MAPPING_KEY = '/api/projections/[mapping_key]',

  // Dashboards
  DASHBOARDS_FILTER_OPTIONS_CARRIER = '/api/dashboards/filters/carrier/[carrier_id]',
  DASHBOARDS_TERRITORY_RANKING_AND_MARKET_SHARE = '/api/dashboards/territory/ranking-and-market-share',
  DASHBOARDS_TERRITORY_HIERARCHY = '/api/dashboards/territory/hierarchy',
  DASHBOARDS_TERRITORY_OPTIONS = '/api/dashboards/territory/options',
  DASHBOARDS_TERRITORY_TOP_AGENTS = '/api/dashboards/territory/top-agents',
  DASHBOARDS_TERRITORY_TOP_EMPLOYERS_NOT_WITH_CARRIER = '/api/dashboards/territory/top-employers-not-with-carrier',
  DASHBOARDS_TERRITORY_MAPPING_KEY_RANKING_AND_MARKET_SHARE = '/api/dashboards/territory/[mapping_key]',

  // MoO Custom Dashboards (BFR-1173)
  DASHBOARDS_MOO_CUSTOM_EMPLOYER_EMPLOYEE_STATS = '/api/dashboards/moo-custom/employer-employee-stats',
  DASHBOARDS_MOO_CUSTOM_MARKET_POTENTIAL_STATS = '/api/dashboards/moo-custom/market-potential-stats',
  DASHBOARDS_MOO_CUSTOM_MARKET_POTENTIAL_BY_PRODUCT_STATS = '/api/dashboards/moo-custom/market-potential-by-product-stats',
  DASHBOARDS_MOO_CUSTOM_SOLD_PREMIUM_BY_PRODUCT = '/api/dashboards/moo-custom/sold-premium-by-product',
  DASHBOARDS_MOO_CUSTOM_PENETRATION_RATE_BY_PRODUCT = '/api/dashboards/moo-custom/penetration-rate-by-product',
  DASHBOARDS_MOO_CUSTOM_TERRITORY_HIERARCHY = '/api/dashboards/moo-custom/territory-hierarchy',
  DASHBOARDS_MOO_CUSTOM_TERRITORY_OPTIONS = '/api/dashboards/moo-custom/territory-options',
}

export enum CustomAuthErrors {
  InitFailed = 'InitFailed',
  NoExistingAccount = 'NoExistingAccount',
  AccountDisabled = 'AccountDisabled',
  AccountExists = 'AccountExists',
}

// See https://next-auth.js.org/configuration/pages#sign-in-page for many error definitions
// Should also handle any defined in CustomAuthErrors
export const AuthErrorMessages = {
  [CustomAuthErrors.InitFailed]: 'Initialization of the user and/or account failed.',
  [CustomAuthErrors.NoExistingAccount]: 'No existing account is associated with the authentication provided.',
  [CustomAuthErrors.AccountDisabled]: 'Account has been deactivated.',
  [CustomAuthErrors.AccountExists]: 'An account already exists with the authentication provided.',
  OAuthSignin: 'Error in constructing an authorization URL.',
  OAuthCallback: 'Error in handling the response from an OAuth provider.',
  OAuthCreateAccount: 'Could not create OAuth provider user in the database.',
  EmailCreateAccount: 'Could not create email provider user in the database.',
  Callback: 'Error in the OAuth callback handler route.',
  OAuthAccountNotLinked: 'The email on the account is already linked, but not with this OAuth account.',
  EmailSignin: 'Sending the e-mail with the verification token failed.',

  // The following messages are less than ideal
  CredentialsSignin: 'The authorize callback returned null in the Credentials provider.',
  SessionRequired: 'The content of this page requires you to be signed in at all times.',
  Default: 'Catch-all.',
};

export enum EntityTypeCollectionEnum {
  EMPLOYER = 'employers',
  BROKER = 'brokers',
  AGENT = 'agents',
  CARRIER = 'carriers',
}

export const EntityTypeCollectionToEntityType: Record<EntityTypeCollectionEnum, EntityType> = {
  [EntityTypeCollectionEnum.EMPLOYER]: EntityType.EMPLOYER,
  [EntityTypeCollectionEnum.BROKER]: EntityType.BROKER,
  [EntityTypeCollectionEnum.AGENT]: EntityType.AGENT,
  [EntityTypeCollectionEnum.CARRIER]: EntityType.CARRIER,
};

export const EntityTypeToEntityTypeCollection: Record<EntityType, EntityTypeCollectionEnum> = {
  [EntityType.EMPLOYER]: EntityTypeCollectionEnum.EMPLOYER,
  [EntityType.BROKER]: EntityTypeCollectionEnum.BROKER,
  [EntityType.AGENT]: EntityTypeCollectionEnum.AGENT,
  [EntityType.CARRIER]: EntityTypeCollectionEnum.CARRIER,
};

export const EntityTypeCollectionPrintLinks: Record<EntityTypeCollectionEnum, RouteEnum> = {
  [EntityTypeCollectionEnum.EMPLOYER]: RouteEnum.EMPLOYER_PRINT,
  [EntityTypeCollectionEnum.BROKER]: RouteEnum.BROKER_PRINT,
  [EntityTypeCollectionEnum.AGENT]: RouteEnum.AGENT_PRINT,
  [EntityTypeCollectionEnum.CARRIER]: RouteEnum.CARRIER_PRINT,
};

export const EntityTypeCollectionDynamicIDKey: Record<EntityTypeCollectionEnum, string> = {
  [EntityTypeCollectionEnum.EMPLOYER]: '[company_id]',
  [EntityTypeCollectionEnum.BROKER]: '[broker_id]',
  [EntityTypeCollectionEnum.AGENT]: '[npn]',
  [EntityTypeCollectionEnum.CARRIER]: '[carrier_id]',
};

export const EntityTypeOverviewRoute: Record<EntityType, RouteEnum> = {
  [EntityType.EMPLOYER]: RouteEnum.EMPLOYER_OVERVIEW,
  [EntityType.BROKER]: RouteEnum.BROKER_OVERVIEW,
  [EntityType.AGENT]: RouteEnum.AGENT_OVERVIEW,
  [EntityType.CARRIER]: RouteEnum.CARRIER_OVERVIEW,
};

export enum NotificationClassEnum {
  PINNED = 'pinned',
  USER = 'user',
}

export enum NotificationTypeEnum {
  TEAM_USER_ACTION,
  REPORT,
  SUBSCRIPTION,
}

export enum FileExportStatusEnum {
  FAILED = 'Failed',
  GENERATING = 'Generating...',
  READY_FOR_DOWNLOAD = 'Ready for download',
}

export enum NodeTypeEnum {
  OPPORTUNITY = 1,
  RISK,
}

export enum ColorEnum {
  WHITE = '#fff',
  BLACK = '#020202',
  BLUE = '#546de3',
  PRIMARY_BLUE = '#0e2673',
  DARK_SLATE_BLUE = '#354989',
  PRIMARY_ORANGE = '#f35d5b',
  PASTEL_ORANGE = '#f8ad4b',
  DARK_PASTEL_BLUE = '#7898d5',
  GREEN = '#18c43e',
  APPLE_GREEN = '#75ca18',
  LIGHT_GREEN = '#d9ffe1',
  SAFETY_ORANGE = '#ff6b00',
  PURPLE_HEART = '#651ea2',
  ROYAL_BLUE = '#5577e7',
  CARIBBEAN_GREEN = '#0cc59b',
  OUTRAGEOUS_ORANGE = '#fd753e',
  MUNSELL = '#edeff4',
  RED = '#ea444f',
}

export const DEFAULT_COLOR_SWATCH = [
  ColorEnum.PRIMARY_BLUE,
  ColorEnum.DARK_PASTEL_BLUE,
  ColorEnum.PASTEL_ORANGE,
  ColorEnum.PURPLE_HEART,
  ColorEnum.PRIMARY_ORANGE,
  ColorEnum.APPLE_GREEN,
  ColorEnum.SAFETY_ORANGE,
];

export enum TooltipThemeEnum {
  WHITE_BOX = 'white-box',
  UPGRADE_NOW = 'upgrade-now',
  USER_MENU = 'user-menu',
}

export enum ContactEnum {
  HELLO_EMAIL = 'hello@benefeature.com',
}

export enum KeyCodeEnum {
  ENTER = 'Enter',
}

export enum FILTER_TYPES_UI {
  FACETED = 'faceted',
  COMPOUND = 'compound',
  RANGE = 'range',
  CHECKBOX = 'checkbox',
}

export enum HttpStatusEnum {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  TEMPORARY_REDIRECT = 303,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  INTERNAL_SERVER_ERROR = 500,
}

export enum PERMISSIONS {
  IS_AUTHENTICATED = 'Is Authenticated',
  TEAM_MANAGER = 'Team Manager',
  SYSTEM_ADMIN = 'System Admin',
}

export enum SUBSCRIPTIONS {
  PRO = 'Pro',
  ENTERPRISE = 'Enterprise',
}

export enum EMAIL_TEMPLATES {
  LOGIN_EMAIL_LOGIN = 'login/email-login',
  SIGNUP_WELCOME = 'signup/welcome',
  SHARE_PROFILE = 'share/share-profile',
  ENTERPRISE_MORE_INFO_REQUEST = 'signup/enterprise-request',
}
